<template>
  <b-card title="ตารางรายการถอนเงิน">
    <b-overlay
      :show="loading"
      rounded="sm"
    >
      <!-- table -->
      <vue-good-table
        :columns="columns"
        :rows="paymentList"
        :rtl="direction"
        :search-options="{
          enabled: true,
          externalQuery: searchTerm,
        }"
        :select-options="{
          //enabled: true,
          //selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
          //selectionInfoClass: 'custom-class',
          //selectionText: 'rows selected',
          //clearSelectionText: 'clear',
          //disableSelectInfo: true, // disable the select info panel on top
          //selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
        }"
        :pagination-options="{
          enabled: true,
          perPage: pageLength,
        }"
        @on-selected-rows-change="selectionChanged"
        @on-page-change="paginationFunc"
        @on-per-page-change="itemPerpageFunc"
        @on-sort-change="sortFunc"
      >
        <!-- Selected: Delete -->
        <div slot="selected-row-actions">
          <b-button variant="gradient-danger">
            ลบ
          </b-button>
        </div>
        <template
          slot="table-row"
          slot-scope="props"
        >
          <!-- Column: Status -->
          <span
            v-if="props.column.field === 'status'"
            class="text-nowrap"
          >
            <span v-if="props.row.status === 'new'">
              <b-badge
                variant="info"
                class="badge-glow"
              >
                รายการใหม่
              </b-badge>
            </span>
            <span v-else-if="props.row.status === 'completed'">
              <b-badge
                variant="success"
                class="badge-glow"
              >
                ข้อมูลถูกต้อง
              </b-badge>
            </span>
            <span v-else-if="props.row.status === 'checked'">
              <b-badge
                variant="success"
                class="badge-glow"
              >
                ผ่านการตรวจสอบ
              </b-badge>
            </span>
            <span v-else-if="props.row.status === 'rejected'">
              <b-badge
                variant="danger"
                class="badge-glow"
              >
                ไม่ผ่านการตรวจสอบ
              </b-badge>
            </span>
            <!-- <span v-else-if="props.row.status === 'waiting'">
              <b-badge variant="warning" class="badge-glow">
                รอดำเนินการ
              </b-badge>
            </span> -->
          </span>

          <!-- Column: Slip -->
          <span
            v-else-if="props.column.field === 'slip'"
            class="text-nowrap"
          >
            <a
              :href="BaseAPIURL + props.row.slip"
              target="_blank"
            >สลิปเงิน</a>
          </span>

          <!-- Column: transferAmount -->
          <span
            v-else-if="props.column.field === 'transferAmount'"
            class="text-nowrap"
          >
            {{ props.row.transferAmount.toLocaleString() }}
          </span>

          <!-- Column: Slip -->
          <span
            v-else-if="props.column.field === 'payDate'"
            class="text-nowrap"
          >
            <span>{{
              moment(props.row.payDate).format("MMMM Do YYYY, h:mm:ss a")
            }}</span>
          </span>


          <!-- Column: Common -->
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>

        <!-- Column: loading -->
        <template slot="loadingContent">
          <b-spinner
            variant="primary"
            label="Text Centered"
          />
        </template>

        <!-- pagination -->
        <template
          slot="pagination-bottom"
          slot-scope="props"
        >
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap ">
                Showing 1 to
              </span>
              <b-form-select
                v-model="pageLength"
                :options="perPageValue"
                class="mx-1"
                @input="
                  (value) => props.perPageChanged({ currentPerPage: value })
                "
              />
              <span class="text-nowrap"> of {{ totalCount }} entries </span>
            </div>
            <div>
              <b-pagination
                :value="pageValue"
                :total-rows="totalCount"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value) => props.pageChanged({ currentPage: value })"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
    </b-overlay>
  </b-card>
</template>

<script>
import {
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BButton,
  BSpinner,
  VBTooltip,
  BCard,
  BCardText,
  BOverlay,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import moment from 'moment'
// import dataDummy from "./data";
import useJwt from '@/auth/jwt/useJwt'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import BaseAPIURL from '@core/app-config/baseAPIURL'
import perPageValue from '@/constants/perPagedropdown'
import store from '@/store/index'
import configFunction from "@/constants/functional";

export default {
  components: {
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BButton,
    BSpinner,
    BCard,
    BCardText,
    BOverlay,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    // vendorList: Array,
    // table: String,
    // where: String,
  },
  data() {
    return {
      loading: false,
      btnLoading: false,
      // API
      collectionAPI: '/send-data-log-with-draws', // ชื่อ API
      whereLimit: '&_limit=', // condition  ตั้งต้น, ตัวอย่างการเขียน -> "&_limit=5",
      whereSortList: [],
      whereSortCondition: '',
      wherePagination: '',
      whereSearchCondition: '',
      whereQueryDefaultCondition: '&_sort=created_at:desc', // Qeury เริ่มต้น, ตัวอย่างการเขียน -> "&delete=false",
      whereCondition: '',
      // Table
      pageLength: 0,
      pageValue: 1,
      dir: false,
      columns: [
        {
          label: "ชื่อ - สกุล",
          field: "customerName",
        },
        {
          label: 'บัญชีผู้ใช้',
          field: 'c_username',
        },
        {
          label: 'ถอนเงิน (บาท)',
          field: 'transferAmount',
          type: 'number',
          tdClass: 'text-left',
        },
        {
          label: 'จัดส่งข้อมูล',
          field: 'created_at',
        },
      ],
      rows: [],
      searchTerm: '',
      // paymentList: dataDummy.paymentList, // import from data Dummy
      paymentList: [],
      totalCount: 0,
      moment,
      BaseAPIURL,
      perPageValue,
    }
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  created() {
    // Set Page Data
    this.pageLength = this.perPageValue[0]
    this.whereLimit = `${this.whereLimit}${this.perPageValue[0]}`
    // Get Data
    this.getDataTotal()
    this.fetchData()
  },
  mounted() {
    // Get Data
    setInterval(() => {
      this.getDataTotal();
      this.fetchData();
    }, configFunction.refreshTime);
  },
  methods: {
    selectionChanged(e) {
      console.log(e)
    },
    sendData() {
      this.btnLoading = true
    },
    getDataTotal() {
      this.whereCondition = `${
        this.whereLimit && this.whereLimit !== '' ? this.whereLimit : ''
      }${
        this.whereSortCondition && this.whereSortCondition !== ''
          ? this.whereSortCondition
          : ''
      }${
        this.wherePagination && this.wherePagination !== ''
          ? this.wherePagination
          : ''
      }${
        this.whereSearchCondition && this.whereSearchCondition !== ''
          ? this.whereSearchCondition
          : ''
      }${
        this.whereQueryDefaultCondition
        && this.whereQueryDefaultCondition !== ''
          ? this.whereQueryDefaultCondition
          : ''
      }`

      return useJwt
        .get(this.collectionAPI, `count?${this.whereCondition}`)
        .then(res => {
          if (res.status === 200) {
            // eslint-disable-next-line no-return-assign
            return (this.totalCount = res.data)
          }
          throw res.data.message
        })
        .catch(err => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Not Found',
              icon: 'XCircleIcon',
              variant: 'danger',
              text: `${err}`,
            },
          })
        })
    },
    fetchData() {
      this.loading = true

      // รวม Query
      this.whereCondition = `${
        this.whereLimit && this.whereLimit !== '' ? this.whereLimit : ''
      }${
        this.whereSortCondition && this.whereSortCondition !== ''
          ? this.whereSortCondition
          : ''
      }${
        this.wherePagination && this.wherePagination !== ''
          ? this.wherePagination
          : ''
      }${
        this.whereSearchCondition && this.whereSearchCondition !== ''
          ? this.whereSearchCondition
          : ''
      }${
        this.whereQueryDefaultCondition
        && this.whereQueryDefaultCondition !== ''
          ? this.whereQueryDefaultCondition
          : ''
      }`

      return useJwt
        .query(this.collectionAPI, this.whereCondition)
        .then(res => {
          if (res.status === 200) {
            console.log('res', res)
            res && res.data && res.data.length > 0
              ? (this.paymentList = res.data.map((item, index) => ({
                id: item.payload.id ? item.payload.id : '',
                customerName: item.payload.customerName ? item.payload.customerName : '',
                c_username: item.payload.c_username
                  ? item.payload.c_username
                  : '',
                transferAmount: item.payload.transferAmount
                  ? item.payload.transferAmount
                  : '',
                created_at: item.created_at
                  ? moment(item.created_at).locale("th").format(
                    'Do MMMM YYYY HH:mm น.',
                  )
                  : '',
                ownBank: item.payload.ownBank ? item.payload.ownBank : '',
              })))
              : (this.paymentList = [])
          } else {
            throw res.data.message
          }
        })
        .catch(err => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error',
              icon: 'BellIcon',
              variant: 'danger',
              text: err,
            },
          })
        })
        .finally(() => {
          this.loading = false
          console.log('this.paymentList', this.paymentList)
        })
    },
    searchSubmit(e) {
      this.loading = true
      const searchData = JSON.parse(JSON.stringify(e))
      const customerRefUser = searchData && searchData.customerRefUser
        ? `customerRefUser_contains=${searchData.customerRefUser}`
        : ''
      const status = searchData && searchData.status
        ? `status_contains=${searchData.status}`
        : ''
      // ต้องเรียงเอง
      this.whereSearchCondition = `${customerRefUser ? `&${customerRefUser}` : ''
      }${status ? `&${status}` : ''}`

      this.wherePagination = ''

      this.getDataTotal().then(() => {
        this.fetchData()
      })
    },
    itemPerpageFunc(data) {
      this.loading = true
      this.whereLimit = `&_limit=${data.currentPerPage}`
      this.wherePagination = ''

      this.getDataTotal().then(() => {
        this.fetchData()
      })
    },
    paginationFunc(data) {
      this.loading = true
      if (data.currentPage > 1) {
        this.wherePagination = `&_start=${(data.currentPage - 1)
          * this.pageLength}`
      } else {
        this.wherePagination = ''
      }

      this.getDataTotal().then(() => {
        this.fetchData()
      })
    },
    sortFunc(data) {
      this.loading = true
      this.whereSortCondition = '&_sort='
      for (let i = 0; i < data.length; i++) {
        if (data.length === 1 || i === data.length - 1) {
          this.whereSortCondition += `${data[i].field}:${data[i].type}`
        } else if (i < data.length - 1) {
          this.whereSortCondition = `${this.whereSortCondition}${data[i].field}:${data[i].type}` + ','
        } else {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Unsuccessful',
              icon: 'XCircleIcon',
              variant: 'danger',
              text: 'เกิดเหตุการณ์ผิดปกติ กรุณา refresh หน้าจอ',
            },
          })
          return false
        }
      }
      this.getDataTotal().then(() => {
        this.fetchData()
      })
    },
  },
}
</script>
